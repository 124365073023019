@use '../../../assets/styles/variables/colors/colors';
@use '../../../assets/styles/variables/typography';
@use '../../../assets/styles/variables/spaces';

.count {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: typography.$font-size-tips;
  min-width: spaces.$space-size-4;
  padding-inline: spaces.$space-size-1;
  height: spaces.$space-size-4;
  border-radius: spaces.$space-size-2;
  color: colors.$color-white;
}

.default {
  background-color: colors.$color-gray-70;
}

.warning {
  background-color: colors.$color-warning;
  color: colors.$color-black;
}

.success {
  background-color: colors.$color-success-dark;
}

.error {
  background-color: colors.$color-alert-dark;
}
