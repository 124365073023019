@use 'src/assets/styles/variables/border-radius';
@use 'src/assets/styles/variables/colors/colors';
@use 'src/assets/styles/variables/sizes';
@use 'src/assets/styles/variables/spaces';
@use 'src/assets/styles/variables/typography';

// wrapper is only for specificity
// to override react-day-picker styles
.wrapper {
  margin: 0;

  .button {
    color: colors.$color-text;

    &:active,
    &:focus {
      border-color: colors.$color-gray-15;
    }
  }

  .cell {
    padding: 2px 0;
  }

  .dayOutside {
    color: colors.$color-gray-50;
  }

  .day,
  .weekNumber {
    font-weight: typography.$font-weight-medium;

    &:hover,
    &:focus-visible,
    &:focus {
      background-color: colors.$color-gray-15;
      color: colors.$color-text;
    }
  }

  .day {
    border-width: 1px;
  }

  .weekNumber {
    border-width: 0;
    color: var(colors.$css-var-name-theme-color-dark);
    margin-right: spaces.$space-size-3;
    background-color: transparent;

    &:disabled {
      background-color: transparent;
      color: colors.$color-gray-60;
    }

    &:not([disabled]) {
      &:hover,
      &:focus-visible,
      &:active,
      &:focus {
        background-color: transparent;
        color: var(colors.$css-var-name-theme-color-dark);
      }
    }
  }

  .dayToday {
    border-color: var(colors.$css-var-name-theme-color-dark);

    &.rangeDateMiddle {
      border-color: var(colors.$css-var-name-theme-color-light);
    }
  }

  .daySelected:not(.dayOutside) {
    background-color: var(colors.$css-var-name-theme-color-dark);
    color: colors.$color-white;

    &.dayToday {
      border-color: var(colors.$css-var-name-theme-color-dark);
    }
  }

  .rangeDateStart,
  .rangeDateEnd,
  .rangeDateMiddle {
    &:not(.dayOutside) {
      position: relative;
      background-color: var(colors.$css-var-name-theme-color);
      overflow: visible;
      border-radius: 50%;

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: -1px;
        bottom: -1px;
        z-index: -1;
        width: 50%;
        background-color: var(colors.$css-var-name-theme-color-lighter);
        border: inherit;
        border-color: var(colors.$css-var-name-theme-color-lighter);
      }
    }
  }

  .rangeDateStart,
  .rangeDateEnd {
    &:not(.dayOutside) {
      &:active,
      &:focus {
        border-width: 1px;
        background-color: var(colors.$css-var-name-theme-color);
        color: colors.$color-white;
      }
    }
  }

  .rangeDateStart.rangeDateEnd {
    &::after {
      display: none;
    }
  }

  .rangeDateStart:not(.dayOutside) {
    background-color: var(colors.$css-var-name-theme-color-dark);

    &::after {
      left: calc(50% + 1px);
    }
  }

  .rangeDateEnd:not(.dayOutside) {
    background-color: var(colors.$css-var-name-theme-color-dark);

    &::after {
      left: -2px;
    }
  }

  .rangeDateMiddle:not(.dayOutside) {
    background-color: var(colors.$css-var-name-theme-color-lighter);
    color: colors.$color-text;

    &::after {
      left: -1px;
      right: -1px;
      width: auto;
    }
  }

  .navButton {
    border: 1px solid colors.$color-border;
    border-radius: border-radius.$border-radius-small;
    width: sizes.$height-button-small;
    height: sizes.$height-button-small;

    &:hover,
    &:focus-visible,
    &:focus {
      background-color: colors.$color-gray-15;
    }

    &:active {
      background-color: colors.$color-white;
    }

    &[aria-disabled='true'] {
      color: colors.$color-gray-50;
      opacity: 1;
    }
  }

  .captionLabel,
  .headCell {
    text-transform: capitalize;
  }

  .nav {
    display: flex;
    gap: spaces.$space-size-2;

    .hasMultipleMonths & {
      display: block;
    }
  }
}
