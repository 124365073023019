@use '../../../assets/styles/mixins/mixins';
@use '../../../assets/styles/mixins/input-styles.scss';
@use '../../../assets/styles/variables/colors/colors';
@use '../../../assets/styles/variables/sizes';
@use '../../../assets/styles/variables/spaces';

@keyframes success {
  0% {
    background-color: colors.$color-white;
  }

  40% {
    background-color: colors.$color-success;
  }

  60% {
    background-color: colors.$color-success;
  }

  100% {
    background-color: colors.$color-white;
  }
}

.wrapper {
  @include input-styles.input-style-wrapper();
  @include mixins.focus-styles();
  display: block;
  // we need to have this here so we can get the value in js
  // so we can use that time for removing the class again
  animation-duration: 2s;
}

.noBorderRadiusRight {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.alignRight {
  @include input-styles.input-style-align-right();
}

.small {
  @include input-styles.input-style-small();
}

.normal {
  @include input-styles.input-style-normal();
}

.default {
  @include input-styles.input-style-status-default();
}

.error {
  @include input-styles.input-style-status-error();
}

.success {
  @include input-styles.input-style-status-success();
}

.warning {
  @include input-styles.input-style-status-warning();
}

.saved {
  @media (prefers-reduced-motion: no-preference) {
    animation-name: success;
    animation-iteration-count: 1;
  }

  @media (prefers-reduced-motion: reduce) {
    background-color: colors.$color-success;
  }
}

.savedIcon {
  position: absolute;
  top: spaces.$space-size-3;
  right: spaces.$space-size-3;
  color: colors.$color-white;

  .small ~ & {
    right: spaces.$space-size-2;
    top: spaces.$space-size-2;
  }

  .alignRight ~ & {
    right: auto;
    left: spaces.$space-size-3;
  }

  .small.alignRight ~ & {
    left: spaces.$space-size-2;
  }
}

.icon {
  position: absolute;
  top: 50%;
  right: spaces.$space-size-3;
  transform: translateY(-50%);
  color: colors.$color-gray-60;
  width: spaces.$space-size-4;
  height: spaces.$space-size-4;
  pointer-events: none;
}

.hasIcon {
  padding-right: sizes.$height-input;
}
