@use 'src/assets/styles/variables/colors/colors.scss';
@use 'src/assets/styles/variables/typography.scss';
@use 'src/assets/styles/variables/spaces.scss';
@use 'src/assets/styles/variables/sizes.scss' as app-sizes;

.App {
  min-height: calc(100vh - #{app-sizes.$height-header-with-border});
}

.Content {
  // Need position relative to position iframe spinner
  position: relative;
  // This is to make the content follow the tables width
  padding-top: app-sizes.$height-header-with-border;
  display: inline-block;
  min-width: 100%;
}

.DrawerIsPinned {
  transform: translateX(app-sizes.$width-mobile-navigation);
  max-width: calc(100vw - #{app-sizes.$width-mobile-navigation});
  min-width: calc(100vw - #{app-sizes.$width-mobile-navigation});
}
