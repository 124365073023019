@use 'src/assets/styles/variables/spaces';
@use 'src/assets/styles/mixins/media-queries';

.widgetContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: spaces.$space-size-5;
  padding: spaces.$space-size-4 spaces.$space-size-5;
}

.button {
  margin-top: auto;
}
