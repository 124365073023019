@use 'src/assets/styles/variables/spaces.scss';
@use 'src/assets/styles/variables/colors/colors';

.list {
  padding: spaces.$space-size-4;
}

.groupTitle {
  padding-bottom: spaces.$space-size-2;
  border-bottom: 1px solid colors.$color-gray-30;
}

.groupList {
  padding-inline: spaces.$space-size-4;
}
