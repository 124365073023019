@use 'src/assets/styles/mixins/mixins';
@use 'src/assets/styles/mixins/input-styles';
@use 'src/assets/styles/variables/colors/colors';
@use 'src/assets/styles/variables/typography';
@use 'src/assets/styles/variables/spaces';

.selectWrapper {
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: spaces.$space-size-2;
    transform: translateY(-50%);
    border-style: solid;
    border-width: spaces.$space-size-1 spaces.$space-size-1 0;
    border-color: colors.$color-gray-80 transparent transparent;
    pointer-events: none;
  }
}

.select {
  @include mixins.focus-styles();
  @include input-styles.input-style-wrapper();
  padding-right: spaces.$space-size-6;
  line-height: 1;
}

.normal {
  @include input-styles.input-style-normal();
}

.small {
  @include input-styles.input-style-small();
}

.noBorder {
  display: inline-block;
  width: auto;

  .select {
    border-width: 0;
    padding-left: 0;
    line-height: typography.$line-height-body;
    height: auto;

    &[disabled] {
      background-color: transparent;
      color: colors.$color-gray-60;
    }
  }

  &:has([disabled])::after {
    border-top-color: currentColor;
  }
}
