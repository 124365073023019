@use 'src/assets/styles/variables/colors/colors';
@use 'src/assets/styles/variables/sizes';
@use 'src/assets/styles/variables/sizes.scss' as app-sizes;

.Loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0;
  top: app-sizes.$height-header;
  background-color: colors.$color-white;
}
