@use 'src/assets/styles/mixins/media-queries';
@use 'src/assets/styles/mixins/resets';
@use 'src/assets/styles/variables/breakpoints';
@use 'src/assets/styles/variables/colors/colors';
@use 'src/assets/styles/variables/sizes';
@use 'src/assets/styles/variables/spaces';
@use 'src/assets/styles/variables/typography';

.wrapper {
  position: relative;

  &:focus-within,
  &.hasFocus {
    &::after {
      content: '';
      display: block;
      position: absolute;
      right: spaces.$space-size-3;
      bottom: -1px;
      left: spaces.$space-size-8;
      height: 2px;
      background-color: var(colors.$css-var-name-theme-color);
    }
  }
}

.dateInput {
  appearance: none;
  border: 0;
  font-family: typography.$font-family;
  width: 120px;
  padding-left: spaces.$space-size-8;
  height: sizes.$height-input-small;
  font-size: typography.$font-size-body;
  background-color: transparent;

  @include media-queries.until(breakpoints.$breakpoint-small) {
    font-size: typography.$font-size-sub-heading;
  }

  &[disabled] {
    cursor: not-allowed;
    background-color: colors.$color-gray-10;
    color: colors.$color-gray-60;
  }

  // Disable as we take care of it with .dateInputWrapper:focus-within
  // stylelint-disable a11y/no-outline-none
  &:focus {
    outline: 0;
  }
  // stylelint-enable a11y/no-outline-none
}

.dateInputIcon {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: spaces.$space-size-2;
  transform: translateY(-50%);
}
