@use '../../../assets/styles/variables/border-radius';
@use '../../../assets/styles/variables/colors/colors';
@use '../../../assets/styles/variables/shadows';
@use '../../../assets/styles/variables/spaces';
@use '../../../assets/styles/variables/typography';
@use '../../../assets/styles/mixins/mixins';

$width-input-xsmall: 76px;
$width-input-small: 110px;
$width-input-medium: 160px;
$width-input-large: 220px;
$width-input-xlarge: 350px;

// we export these value so we can use them in our tests
:export {
  widthInputXsmall: $width-input-xsmall;
  widthInputSmall: $width-input-small;
  widthInputMedium: $width-input-medium;
  widthInputLarge: $width-input-large;
  widthInputXlarge: $width-input-xlarge;
}

.label {
  &:not(&.label.hidden) {
    font-weight: typography.$font-weight-bold;
    margin-bottom: spaces.$space-size-1;
  }

  &.hidden {
    @include mixins.visually-hidden();
  }
}

.control {
  position: relative;
}

// Sizes
.xSmall {
  width: $width-input-xsmall;
}

.small {
  width: $width-input-small;
}

.medium {
  width: $width-input-medium;
}

.large {
  width: $width-input-large;
}

.xLarge {
  width: $width-input-xlarge;
}

.auto {
  width: auto;
}

.full {
  width: 100%;
}

.validation:not(:empty) {
  margin-top: spaces.$space-size-1;
}

.validationPopOut {
  position: absolute;
  top: calc(100% + #{spaces.$space-size-2});
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  padding: spaces.$space-size-1 spaces.$space-size-2;
  border: 1px solid colors.$color-border;
  background-color: colors.$color-white;
  width: 140px;
  white-space: normal;
  border-radius: border-radius.$border-radius-small;
  box-shadow: shadows.$box-shadow-down;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    left: 50%;
    border-style: solid;
    border-width: 0 spaces.$space-size-3 spaces.$space-size-2;
  }

  &::before {
    border-color: transparent transparent colors.$color-border;
    transform: translateX(-50%);
  }

  &::after {
    border-color: transparent transparent colors.$color-white;
    transform: translate(-50%, 1px);
  }
}
