@use '../../../assets/styles/variables/colors/colors';
@use '../../../assets/styles/variables/spaces';

.wrapper {
  margin: 0;
}

.item {
  & + & {
    margin-top: spaces.$space-size-1;
  }
}

.termIcon {
  color: colors.$color-gray-50;
  width: spaces.$space-size-4;
  height: spaces.$space-size-4;
}

.def {
  margin-left: spaces.$space-size-3;
}
