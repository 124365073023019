@use '../../../assets/styles/variables/typography';
@use '../../../assets/styles/variables/spaces';

.heading {
  &:not(.asLabel) {
    font-size: typography.$font-size-sub-heading;
    font-weight: typography.$font-weight-medium;
    padding: 0;
  }
}

.asLabel {
  margin-bottom: spaces.$space-size-1;
  font-weight: typography.$font-weight-bold;
}

.group {
  border: 0;
  padding: 0;
  margin-right: 0;
  margin-left: 0;
}
