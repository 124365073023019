@use '../../../assets/styles/variables/breakpoints';
@use '../../../assets/styles/variables/colors/colors';
@use '../../../assets/styles/variables/spaces';
@use '../../../assets/styles/mixins/media-queries';

.wrapper {
  display: block;
}

.bg {
  background-color: var(colors.$css-var-name-theme-color-dark);
  color: colors.$color-white;
}

.noPaddingTop {
  .inner {
    padding-top: 0;
  }
}

.noPaddingBottom {
  .inner {
    padding-bottom: 0;
  }
}

.hasBorderTop {
  border-top: 1px solid colors.$color-border;
}

.isSimple {
  .inner {
    @include media-queries.from(breakpoints.$breakpoint-medium) {
      padding-top: spaces.$space-size-4;
      padding-bottom: spaces.$space-size-4;
    }
  }
}

.inner {
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-top: spaces.$space-size-4;
  padding-bottom: spaces.$space-size-4;

  @include media-queries.from(breakpoints.$breakpoint-medium) {
    padding-top: spaces.$space-size-8;
    padding-bottom: spaces.$space-size-8;

    @media (max-height: 1000px) {
      padding-top: spaces.$space-size-5;
      padding-bottom: spaces.$space-size-5;
    }
  }

  > * + * {
    margin-top: spaces.$space-size-3;
  }
}

.item {
  padding-right: spaces.$space-size-4;
  padding-left: spaces.$space-size-4;

  @include media-queries.from(breakpoints.$breakpoint-medium) {
    padding-right: spaces.$space-size-12;
    padding-left: spaces.$space-size-12;
  }

  // this section is to make the section stay on the page in case of overflow on x axis
  &:not(.overflow) {
    position: sticky;
    left: 0;
    z-index: 9;
    width: 100%;
    max-width: calc(100vw - var(--scrollBarWidth, 0px));

    // This is to make sure that dropdown
    // inside will lay over the next .item
    // stylelint-disable selector-max-compound-selectors
    & + & {
      z-index: 8;

      & + & {
        z-index: 7;
      }
    }
    // stylelint-enable selector-max-compound-selectors
  }

  > * + * {
    margin-top: spaces.$space-size-4;
  }
}
