@use '../../../assets/styles/variables/spaces';

.wrapper {
  > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  > * + * {
    margin-top: spaces.$space-size-5;
  }

  &.xxSmall {
    > * + * {
      margin-top: spaces.$space-size-1;
    }
  }

  &.xSmall {
    > * + * {
      margin-top: spaces.$space-size-2;
    }
  }

  &.small {
    > * + * {
      margin-top: spaces.$space-size-3;
    }
  }

  &.medium {
    > * + * {
      margin-top: spaces.$space-size-5;
    }
  }

  &.large {
    > * + * {
      margin-top: spaces.$space-size-6;
    }
  }

  &.xLarge {
    > * + * {
      margin-top: spaces.$space-size-8;
    }
  }

  &.xxLarge {
    > * + * {
      margin-top: spaces.$space-size-12;
    }
  }
}
