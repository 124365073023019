@use 'src/assets/styles/variables/colors/colors';
@use 'src/assets/styles/variables/typography';

.tipsFontMinHeight {
  min-height: typography.$font-size-tips;
}

.bodyFontMinHeight {
  min-height: typography.$font-size-body;
}

.subHeadingFontMinHeight {
  min-height: typography.$font-size-sub-heading;
}

.headingFontMinHeight {
  min-height: typography.$font-size-heading;
}

.displayFontMinHeight {
  min-height: typography.$font-size-display;
}

.fullWidth {
  width: 100%;
}

.fullHeight {
  height: 100%;
}

.halfWidth {
  width: 50%;
}

.halfHeight {
  height: 50%;
}

.quarterWidth {
  width: 25%;
}

.quarterHeight {
  height: 25%;
}

.thirdQuarterWidth {
  width: 75%;
}

.thirdQuarterHeight {
  height: 75%;
}

.autoHeight {
  height: auto;
}

.smallWidth {
  width: 16px;
  min-width: 16px;
}

.normalWidth {
  width: 20px;
  min-width: 20px;
}

.largeWidth {
  width: 24px;
  min-width: 24px;
}

.xLargeWidth {
  width: 32px;
  min-width: 32px;
}

.xxLargeWidth {
  width: 60px;
  min-width: 60px;
}

.isRound {
  border-radius: 50%;
}

.isSquare {
  aspect-ratio: 1 / 1;
}

.animate {
  @media (prefers-reduced-motion: no-preference) {
    animation: shimmer 2s infinite linear;
  }
  display: block;
  background: linear-gradient(
      to right,
      colors.$color-gray-20 4%,
      colors.$color-gray-05 25%,
      colors.$color-gray-20 36%
    )
    right/300% 100%;
  background-size: 1000px 100%;
}

.isInlineBlock {
  display: inline-block;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}
