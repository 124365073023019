@use 'src/assets/styles/variables/spaces';

.actionButton {
  text-transform: none;
}

.navigationButton {
  button {
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}

.ribbon {
  margin: spaces.$space-size-2 0;
}

.periodSelector {
  align-items: center;
}

.ribbonActions {
  margin-left: auto;
  padding: 0 spaces.$space-size-1;
  justify-content: flex-end;
}

.systemAdminContentWrapper {
  padding: 32px 48px 32px 32px;
}

.exchangeRateTableContent {
  min-width: 30%;
  width: fit-content;
}
