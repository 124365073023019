@use 'src/assets/styles/mixins/media-queries.scss';
@use 'src/assets/styles/variables/colors/colors.scss';
@use 'src/assets/styles/variables/spaces.scss';

.ToastContainer {
  z-index: 20;
  transform: translate3d(0, 0, 9999px);
  position: fixed;
  right: spaces.$space-size-3;
  bottom: 0;
  padding: spaces.$space-size-5 spaces.$space-size-3 spaces.$space-size-12;
  width: 520px;
  color: colors.$color-text;

  @include media-queries.till-tablet {
    bottom: 0;
    transform: translateX(0);
    width: 100%;
    left: 0;
    margin: 0;
  }
}

.ToastItem {
  position: relative;
  /* webkit only issue #791 */
  z-index: 0;
  animation-fill-mode: both;
  animation-duration: 0.7s;
}

.CloseButton {
  position: absolute;
  top: 50%;
  right: spaces.$space-size-4;
  transform: translateY(-50%);
}

@media (prefers-reduced-motion: no-preference) {
  .ToastItemAnimateIn {
    animation-name: BounceInRight;
  }

  .ToastItemAnimateOut {
    animation-name: BounceOutRight;
  }
}

@keyframes BounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes BounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes FakeDisableAnimation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@media (prefers-reduced-motion: reduce) {
  .DisableAnimation {
    animation: FakeDisableAnimation 0.6s;
  }
}

@keyframes TrackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .ProgressBar {
    animation: TrackProgress linear 1 forwards;
  }
}
