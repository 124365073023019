@use '../variables/border-radius';
@use '../variables/colors/colors';
@use '../variables/sizes';
@use '../variables/spaces';
@use './mixins';

@mixin boolean-input-wrapper() {
  position: relative;
  display: flex;
  align-items: center;
  min-height: sizes.$height-input;
}

@mixin boolean-input-wrapper-small() {
  min-height: sizes.$height-input-small;
}

@mixin boolean-input-control() {
  @include mixins.visually-hidden();
}

@mixin boolean-input-label() {
  display: flex;
  align-items: center;
}

@mixin boolean-input-label-text() {
  margin-left: spaces.$space-size-2;
}

@mixin boolean-input-pseudo-control($radio: false) {
  display: block;
  min-width: spaces.$space-size-4;
  height: spaces.$space-size-4;
  background-color: colors.$color-white;
  box-shadow: inset 0 0 0 1px colors.$color-input-border;

  @if $radio {
    border-radius: 50%;
  } @else {
    border-radius: border-radius.$border-radius-small;
  }
}
