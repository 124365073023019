@use '../../../assets/styles/variables/colors/colors';
@use '../../../assets/styles/variables/typography';

// need to add .table to take care of specificity
.table {
  .tableCell {
    &_bgWhite {
      background-color: colors.$color-white;
    }

    &_bgPink {
      background-color: colors.$theme-color-pink-lighter;
    }

    &_bgGray {
      background-color: colors.$color-gray-20;
    }

    &_bgLightGray {
      background-color: colors.$color-gray-05;
    }

    &_bgAlert {
      background-color: colors.$color-alert;
      color: colors.$color-white;
    }

    &_bgWarning {
      background-color: colors.$color-warning;
    }

    &_bgSuccess {
      background-color: colors.$color-success;
      color: colors.$color-white;
    }

    // Background colors for availability
    &[class*='_bgAvailability'] {
      font-weight: typography.$font-weight-normal;
    }

    &_bgAvailability00 {
      background-color: colors.$color-availability-00;
    }

    &_bgAvailability05 {
      background-color: colors.$color-availability-05;
    }

    &_bgAvailability10 {
      background-color: colors.$color-availability-10;
    }

    &_bgAvailability15 {
      background-color: colors.$color-availability-15;
    }

    &_bgAvailability20 {
      background-color: colors.$color-availability-20;
    }

    &_bgAvailability25 {
      background-color: colors.$color-availability-25;
    }

    &_bgAvailability30 {
      background-color: colors.$color-availability-30;
    }

    &_bgAvailability35 {
      background-color: colors.$color-availability-35;
    }

    &_bgAvailability40 {
      background-color: colors.$color-availability-40;
    }

    &_bgAvailability45 {
      background-color: colors.$color-availability-45;
    }

    &_bgAvailability50 {
      background-color: colors.$color-availability-50;
    }

    &_bgAvailability55 {
      background-color: colors.$color-availability-55;
    }

    &_bgAvailability60 {
      background-color: colors.$color-availability-60;
    }

    &_bgAvailability65 {
      background-color: colors.$color-availability-65;
    }

    &_bgAvailability70 {
      background-color: colors.$color-availability-70;
    }

    &_bgAvailability75 {
      background-color: colors.$color-availability-75;
    }

    &_bgAvailability80 {
      background-color: colors.$color-availability-80;
    }

    &_bgAvailability85 {
      background-color: colors.$color-availability-85;
    }

    &_bgAvailability90 {
      background-color: colors.$color-availability-90;
    }

    &_bgAvailability95 {
      background-color: colors.$color-availability-95;
    }

    &_bgAvailability100 {
      background-color: colors.$color-availability-100;
    }

    &_bgAvailabilityNegative05 {
      background-color: colors.$color-availability--05;
    }

    &_bgAvailabilityNegative10 {
      background-color: colors.$color-availability--10;
    }

    &_bgAvailabilityNegative15 {
      background-color: colors.$color-availability--15;
    }

    &_bgAvailabilityNegative20 {
      background-color: colors.$color-availability--20;
      color: #212121;
    }

    // Background colors for heat map
    &[class*='_bgHeatmap'] {
      font-weight: typography.$font-weight-normal;
    }

    &_bgHeatmap00 {
      background-color: colors.$color-heatmap-00;
    }

    &_bgHeatmap05 {
      background-color: colors.$color-heatmap-05;
    }

    &_bgHeatmap10 {
      background-color: colors.$color-heatmap-10;
    }

    &_bgHeatmap15 {
      background-color: colors.$color-heatmap-15;
    }

    &_bgHeatmap20 {
      background-color: colors.$color-heatmap-20;
    }

    &_bgHeatmap25 {
      background-color: colors.$color-heatmap-25;
    }

    &_bgHeatmap30 {
      background-color: colors.$color-heatmap-30;
    }

    &_bgHeatmap35 {
      background-color: colors.$color-heatmap-35;
    }

    &_bgHeatmap40 {
      background-color: colors.$color-heatmap-40;
    }

    &_bgHeatmap45 {
      background-color: colors.$color-heatmap-45;
    }

    &_bgHeatmap50 {
      background-color: colors.$color-heatmap-50;
    }

    &_bgHeatmap55 {
      background-color: colors.$color-heatmap-55;
    }

    &_bgHeatmap60 {
      background-color: colors.$color-heatmap-60;
    }

    &_bgHeatmap65 {
      background-color: colors.$color-heatmap-65;
    }

    &_bgHeatmap70 {
      background-color: colors.$color-heatmap-70;
    }

    &_bgHeatmap75 {
      background-color: colors.$color-heatmap-75;
    }

    &_bgHeatmap80 {
      background-color: colors.$color-heatmap-80;
    }

    &_bgHeatmap85 {
      background-color: colors.$color-heatmap-85;
    }

    &_bgHeatmap90 {
      background-color: colors.$color-heatmap-90;
    }

    &_bgHeatmap95 {
      background-color: colors.$color-heatmap-95;
    }

    &_bgHeatmap100 {
      background-color: colors.$color-heatmap-100;
    }

    &_bgHeatmap105 {
      background-color: colors.$color-heatmap-105;
    }

    &_bgHeatmap110 {
      background-color: colors.$color-heatmap-110;
    }

    &_bgHeatmap115 {
      background-color: colors.$color-heatmap-115;
    }

    &_bgHeatmap120 {
      background-color: colors.$color-heatmap-120;
    }

    // Text colors for heatmap to make them accessible
    &_bgHeatmap00,
    &_bgHeatmap05,
    &_bgHeatmap10,
    &_bgHeatmap15,
    &_bgHeatmap20 {
      color: #161616;
    }

    &_bgHeatmap120 {
      color: #212121;
    }
  }
}
