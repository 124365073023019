@use 'sass:math';
@use '../../../assets/styles/mixins/media-queries';
@use '../../../assets/styles/variables/breakpoints';
@use '../../../assets/styles/variables/grid-settings';

.grid {
  width: 100%;
}

.limitWidth {
  max-width: 1400px;
}

.inner {
  display: flex;
  flex-wrap: wrap;
  margin-top: -(grid-settings.$grid-gutter);
  margin-left: -(grid-settings.$grid-gutter);
}

.innerVerticalGutter {
  margin-top: -(grid-settings.$grid-gutter);

  .gridItem {
    margin-top: grid-settings.$grid-gutter;
  }
}

.innerAlignBottom {
  align-items: flex-end;
}

.gridItem {
  flex: 1 0 auto;
  margin-top: #{grid-settings.$grid-gutter};
  margin-left: #{grid-settings.$grid-gutter};
  min-width: calc(#{math.div(100%, grid-settings.$grid-items)} - #{grid-settings.$grid-gutter});
}

// stylelint-disable function-parentheses-space-inside
@for $i from 1 through grid-settings.$grid-items {
  .gridItem_#{$i} {
    flex-grow: 0;
    // Use width instead of flex-basis as IE11 calculates width wrong with padding
    // See: https://github.com/philipwalton/flexbugs#flexbug-7
    width: calc(#{math.div(100%, grid-settings.$grid-items) * $i} - #{grid-settings.$grid-gutter});
  }
}

@for $i from 1 through grid-settings.$grid-items {
  .gridItemSm_#{$i} {
    @include media-queries.from(breakpoints.$breakpoint-xsmall) {
      flex-grow: 0;
      width: calc(
        #{math.div(100%, grid-settings.$grid-items) * $i} - #{grid-settings.$grid-gutter}
      );
    }
  }
}

@for $i from 1 through grid-settings.$grid-items {
  .gridItemMd_#{$i} {
    @include media-queries.from(breakpoints.$breakpoint-small) {
      flex-grow: 0;
      width: calc(
        #{math.div(100%, grid-settings.$grid-items) * $i} - #{grid-settings.$grid-gutter}
      );
    }
  }
}

@for $i from 1 through grid-settings.$grid-items {
  .gridItemLg_#{$i} {
    @include media-queries.from(breakpoints.$breakpoint-large) {
      flex-grow: 0;
      width: calc(
        #{math.div(100%, grid-settings.$grid-items) * $i} - #{grid-settings.$grid-gutter}
      );
    }
  }
}

@for $i from 1 through grid-settings.$grid-items {
  .gridItemXl_#{$i} {
    @include media-queries.from(breakpoints.$breakpoint-xlarge) {
      flex-grow: 0;
      width: calc(
        #{math.div(100%, grid-settings.$grid-items) * $i} - #{grid-settings.$grid-gutter}
      );
    }
  }
}

@for $i from 1 through grid-settings.$grid-items {
  .gridItemPush_#{$i} {
    margin-left: calc(
      #{(math.div(100%, grid-settings.$grid-items) * $i)} + #{grid-settings.$grid-gutter}
    );
  }
}

@for $i from 1 through grid-settings.$grid-items {
  .gridItemXlPush_#{$i} {
    @include media-queries.from(breakpoints.$breakpoint-xlarge) {
      margin-left: calc(
        #{(math.div(100%, grid-settings.$grid-items) * $i)} + #{grid-settings.$grid-gutter}
      );
    }
  }
}
// stylelint-enable function-parentheses-space-inside

.gridClearFix {
  width: 100%;
}
