@use '../../../assets/styles/mixins/bool-input';
@use '../../../assets/styles/variables/colors/colors';
@use '../../../assets/styles/variables/typography';
@use '../../../assets/styles/variables/spaces';

.wrapper {
  @include bool-input.boolean-input-wrapper();
}

.label {
  @include bool-input.boolean-input-label();
}

.labelText {
  @include bool-input.boolean-input-label-text();
}

.pseudoControl {
  @include bool-input.boolean-input-pseudo-control();
  position: relative;
  display: inline-flex;
  align-items: center;
  vertical-align: top; // Removes 5x underneath
  color: colors.$color-gray-70;
  box-shadow: none;

  &::before {
    content: '';
    min-width: spaces.$space-size-8;
    width: spaces.$space-size-8;
    height: spaces.$space-size-5;
    border-radius: 1000px;
    transition: background-color 0.2s ease;
    background-color: colors.$color-gray-40;
    border: 1px solid colors.$color-gray-40;
  }

  @media screen and (prefers-reduced-motion: reduce) {
    &::before {
      transition: none;
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: spaces.$space-size-4;
    height: spaces.$space-size-4;
    background-color: colors.$color-white;
    border-radius: 50%;
    margin: 0 3px;
    transition: color 0.2s ease, transform 0.2s ease;
  }

  @media screen and (prefers-reduced-motion: reduce) {
    &::after {
      transition: none;
    }
  }

  &.error::before {
    border-color: colors.$color-alert;
  }

  &.success::before {
    border-color: colors.$color-success;
  }

  &.warning::before {
    border-color: colors.$color-warning;
  }
}

.control {
  @include bool-input.boolean-input-control();

  &:checked {
    + .label .pseudoControl {
      &::before {
        background-color: colors.$color-success;
      }

      &::after {
        transform: translateX(10px);
      }
    }
  }

  &:focus {
    + .label .pseudoControl {
      box-shadow: colors.$color-focus;
    }
  }

  &:focus:not(:focus-visible) {
    + .label .pseudoControl {
      outline: 0;
    }
  }

  &[disabled] {
    ~ .label {
      cursor: not-allowed;

      .pseudoControl::before {
        background-color: colors.$color-gray-20;
      }

      .labelText {
        color: colors.$color-gray-60;
      }
    }
  }
}
