@use '../../../assets/styles/variables/spaces';

.mediaObject {
  display: flex;
}

.verticalCenter {
  align-items: center;
}

.small {
  .image {
    margin-right: spaces.$space-size-2;
  }
}

.image {
  margin-right: spaces.$space-size-5;
}
