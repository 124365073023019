@use './theme';

$theme-color-purple: #9e7ea7;
$theme-color-purple-dark: #6e5075;
$theme-color-purple-light: #ebdbf0;
$theme-color-purple-lighter: #faf2fc;

// purple theme map for generating css custom properties
$theme-map-purple: (
  theme.$css-var-name-theme-color: $theme-color-purple,
  theme.$css-var-name-theme-color-dark: $theme-color-purple-dark,
  theme.$css-var-name-theme-color-light: $theme-color-purple-light,
  theme.$css-var-name-theme-color-lighter: $theme-color-purple-lighter,
);
