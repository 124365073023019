@use 'src/assets/styles/mixins/media-queries';
@use 'src/assets/styles/variables/border-radius';
@use 'src/assets/styles/variables/colors/colors';
@use 'src/assets/styles/variables/spaces';
@use 'src/assets/styles/variables/typography';

$widget-min-width: 200px;
$widget-min-height: 150px;
$widget-border-size: 1px;

$widget-border-width: 1px;
$widget-end-of-column: -1;

.widgetDashboard {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax($widget-min-width, 1fr));
  gap: spaces.$space-size-4;
}

.widget {
  min-height: $widget-min-height;
}

.border {
  border: $widget-border-width solid colors.$color-gray-15;
  border-radius: border-radius.$border-radius-default;
}

.widget4by1 {
  grid-column: 1 / #{$widget-end-of-column};
}

.widget3by1 {
  grid-column: span 3;

  @include media-queries.till-tablet {
    grid-column: 1 / #{$widget-end-of-column};
  }
}

.widget2by1 {
  grid-column: span 2;

  @include media-queries.mobile {
    grid-column: 1 / #{$widget-end-of-column};
  }
}

.widget1by1 {
  grid-column: span 1;
}

.widgetHide {
  display: none;
}
