@use 'src/assets/styles/mixins/media-queries.scss';
@use 'src/assets/styles/variables/border-radius.scss';
@use 'src/assets/styles/variables/breakpoints.scss';
@use 'src/assets/styles/variables/spaces.scss';
@use 'src/assets/styles/variables/sizes.scss' as app-sizes;

.Wrapper {
  @include media-queries.from(breakpoints.$breakpoint-xsmall) {
    // On small screens we want the search to take up entire screen
    position: relative;
  }
}

.PopOut {
  position: absolute;
  top: app-sizes.$height-header-with-border;
  right: -(spaces.$space-size-3);
  left: -(spaces.$space-size-3);
  border-radius: 0 0 border-radius.$border-radius-small border-radius.$border-radius-small;

  @include media-queries.from(breakpoints.$breakpoint-xsmall) {
    width: app-sizes.$width-main-search;
    border-top-width: 0;
    left: auto;
  }
}

.SearchResultSpinner {
  padding: spaces.$space-size-4;
}
