@use 'sass:math';
@use '../global/grays';

// Gradient for availability map
$color-availability-alert: #e75757;
$color-availability-success: #62c9af;

$share-positive-availability: math.div(100, 21);
$share-negative-availability: math.div(100, 4);

// stylelint-disable function-parentheses-space-inside
$color-availability-100: $color-availability-success;
$color-availability-95: mix(
  grays.$color-white,
  $color-availability-success,
  ($share-positive-availability * 1%)
);
$color-availability-90: mix(
  grays.$color-white,
  $color-availability-success,
  ($share-positive-availability * 2%)
);
$color-availability-85: mix(
  grays.$color-white,
  $color-availability-success,
  ($share-positive-availability * 3%)
);
$color-availability-80: mix(
  grays.$color-white,
  $color-availability-success,
  ($share-positive-availability * 4%)
);
$color-availability-75: mix(
  grays.$color-white,
  $color-availability-success,
  ($share-positive-availability * 5%)
);
$color-availability-70: mix(
  grays.$color-white,
  $color-availability-success,
  ($share-positive-availability * 6%)
);
$color-availability-65: mix(
  grays.$color-white,
  $color-availability-success,
  ($share-positive-availability * 7%)
);
$color-availability-60: mix(
  grays.$color-white,
  $color-availability-success,
  ($share-positive-availability * 8%)
);
$color-availability-55: mix(
  grays.$color-white,
  $color-availability-success,
  ($share-positive-availability * 9%)
);
$color-availability-50: mix(
  grays.$color-white,
  $color-availability-success,
  ($share-positive-availability * 10%)
);
$color-availability-45: mix(
  grays.$color-white,
  $color-availability-success,
  ($share-positive-availability * 11%)
);
$color-availability-40: mix(
  grays.$color-white,
  $color-availability-success,
  ($share-positive-availability * 12%)
);
$color-availability-35: mix(
  grays.$color-white,
  $color-availability-success,
  ($share-positive-availability * 13%)
);
$color-availability-30: mix(
  grays.$color-white,
  $color-availability-success,
  ($share-positive-availability * 14%)
);
$color-availability-25: mix(
  grays.$color-white,
  $color-availability-success,
  ($share-positive-availability * 15%)
);
$color-availability-20: mix(
  grays.$color-white,
  $color-availability-success,
  ($share-positive-availability * 16%)
);
$color-availability-15: mix(
  grays.$color-white,
  $color-availability-success,
  ($share-positive-availability * 17%)
);
$color-availability-10: mix(
  grays.$color-white,
  $color-availability-success,
  ($share-positive-availability * 18%)
);
$color-availability-05: mix(
  grays.$color-white,
  $color-availability-success,
  ($share-positive-availability * 19%)
);
$color-availability-00: mix(
  grays.$color-white,
  $color-availability-success,
  ($share-positive-availability * 20%)
);
$color-availability--05: mix(
  $color-availability-alert,
  grays.$color-white,
  ($share-negative-availability * 1%)
);
$color-availability--10: mix(
  $color-availability-alert,
  grays.$color-white,
  ($share-negative-availability * 2%)
);
$color-availability--15: mix(
  $color-availability-alert,
  grays.$color-white,
  ($share-negative-availability * 3%)
);
$color-availability--20: $color-availability-alert;
// stylelint-enable function-parentheses-space-inside
