@use '../../../assets/styles/variables/colors/colors';
@use '../../../assets/styles/variables/border-radius';
@use '../../../assets/styles/variables/shadows';
@use '../../../assets/styles/variables/sizes';
@use '../../../assets/styles/variables/spaces';

$width-modal-small: 500;
$width-modal-medium: 700;
$width-modal-large: 900;
$width-modal-xlarge: 1100;
$height-modal-spacing: calc(
  #{spaces.$space-size-5} + var(#{sizes.$css-var-name-height-header}, #{spaces.$space-size-4})
);

// we export these value so we can use them in our tests
:export {
  widthModalSmall: $width-modal-small;
  widthModalMedium: $width-modal-medium;
  widthModalLarge: $width-modal-large;
  widthModalXLarge: $width-modal-xlarge;
}

.wrapper {
  box-sizing: unset;
}

.small {
  max-width: $width-modal-small * 1px;
}

.medium {
  max-width: $width-modal-medium * 1px;
}

.large {
  max-width: $width-modal-large * 1px;
}

.xLarge {
  max-width: $width-modal-xlarge * 1px;
}

.modalHeader,
.modalContent,
.modalFooter {
  padding: spaces.$space-size-6 spaces.$space-size-8;
}

.modalContent {
  padding-block: spaces.$space-size-3;

  &:last-child {
    padding-bottom: spaces.$space-size-8;
  }
}

.modalFooter {
  padding-block: spaces.$space-size-4;
}

.scrollInContent {
  display: flex;
  flex-direction: column;

  .modalContent {
    overflow-y: auto;
    flex: 1;
    border-bottom: 1px solid colors.$color-gray-40;
    border-top: 1px solid colors.$color-gray-40;

    &:first-child {
      border-top-width: 0;
    }

    &:last-child {
      border-bottom-width: 0;
      padding-bottom: spaces.$space-size-3;
    }
  }
}

:global {
  [data-reach-dialog-overlay] {
    background-color: rgba(colors.$color-white, 0.75);
    z-index: 9;
  }

  [data-reach-dialog-content] {
    width: 95vw;
    padding: 0;
    border: 1px solid colors.$color-gray-40;
    border-radius: border-radius.$border-radius-default;
    margin: $height-modal-spacing auto 0;
    overflow: auto;
    max-height: calc(95vh - #{$height-modal-spacing});
    box-shadow: shadows.$box-shadow-down;
  }
}
