@use '../../../assets/styles/variables/border-radius';
@use '../../../assets/styles/variables/colors/colors';
@use '../../../assets/styles/variables/spaces';
@use '../../../assets/styles/variables/typography';

.wrapper {
  position: relative;
  padding: spaces.$space-size-4;
  border-radius: border-radius.$border-radius-default;
  border-style: solid;
  border-width: 1px;
}

.default {
  background: colors.$color-gray-02;
  border-color: colors.$color-gray-20;
}

.error {
  background-color: colors.$color-alert-light;
  color: colors.$color-alert-dark;
  border-color: colors.$color-alert-dark;
}

.success {
  background-color: colors.$color-success-light;
  color: colors.$color-success-dark;
  border-color: colors.$color-success-dark;
}

.warning {
  background-color: colors.$color-warning-light;
  color: colors.$color-text;
  border-color: colors.$color-warning;
}
