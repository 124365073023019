@use '../../../assets/styles/mixins/mixins';
@use '../../../assets/styles/mixins/input-styles.scss';
@use '../../../assets/styles/variables/colors/colors';
@use '../../../assets/styles/variables/spaces';

.wrapper {
  @include input-styles.input-style-wrapper();
  @include mixins.focus-styles();
  vertical-align: top; // this is to remove white space underneath
  padding: spaces.$space-size-2;
  line-height: 1.4;
  min-height: 100px;
}

.error {
  @include input-styles.input-style-status-error();
}

.success {
  @include input-styles.input-style-status-success();
}

.warning {
  @include input-styles.input-style-status-warning();
}

.default {
  @include input-styles.input-style-status-default();
}

.alignRight {
  @include input-styles.input-style-align-right();
}

.small {
  @include input-styles.input-style-small();
}

.normal {
  @include input-styles.input-style-normal();
}
