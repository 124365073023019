@use 'src/assets/styles/mixins/mixins';
@use 'src/assets/styles/variables/typography';
@use 'src/assets/styles/mixins/media-queries';
@use 'src/assets/styles/variables/colors/colors';

.wrapper {
  font-size: inherit;
}

.weight {
  &_bold {
    font-weight: typography.$font-weight-bold;
  }

  &_semibold {
    font-weight: typography.$font-weight-semibold;
  }

  &_medium {
    font-weight: typography.$font-weight-medium;
  }

  &_normal {
    font-weight: typography.$font-weight-normal;
  }
}

$displays: '', '_mobile', '_tablet', '_laptop', '_desktop';
$font-type-sizes: '_subtips', '_tips', '_body', '_subHeading', '_heading', '_display';

@mixin construct-display($display-type, $font-size) {
  @if $display-type  !='' {
    @if $display-type =='_mobile' {
      @include media-queries.mobile {
        font-size: $font-size;
      }
    }

    @if $display-type =='_tablet' {
      @include media-queries.tablet {
        font-size: $font-size;
      }
    }

    @if $display-type =='_laptop' {
      @include media-queries.laptop {
        font-size: $font-size;
      }
    }

    @if $display-type =='_desktop' {
      @include media-queries.desktop {
        font-size: $font-size;
      }
    }
  }

  @else {
    font-size: $font-size;
  }
}

@mixin construct-size($display-type, $font-size) {
  @if $font-size =='_subtips' {
    @include construct-display($display-type, typography.$font-size-subtips)
  }

  @if $font-size =='_tips' {
    @include construct-display($display-type, typography.$font-size-tips);
  }

  @if $font-size =='_body' {
    @include construct-display($display-type, typography.$font-size-body);
  }

  @if $font-size =='_subHeading' {
    @include construct-display($display-type, typography.$font-size-sub-heading);
  }

  @if $font-size =='_heading' {
    @include construct-display($display-type, typography.$font-size-heading);
  }

  @if $font-size =='_display' {
    @include construct-display($display-type, typography.$font-size-display);
  }
}

.size {
  @each $display-type in $displays {
    @each $font-type-size in $font-type-sizes {
      &#{$display-type}#{$font-type-size} {
        @include construct-size($display-type, $font-type-size);
      }
    }
  }
}

.inheritSize {
  font-size: inherit;
}

.tone {
  &_default {
    color: inherit;
  }
}

.emphasize {
  color: colors.$theme-color-pink-dark;
}

.tone {
  &_error {
    color: colors.$color-alert-dark;
  }

  &_success {
    color: colors.$color-success-dark;
  }

  &_warning {
    color: colors.$color-warning;
  }
}

.textAlign {
  &_left {
    text-align: left;
  }

  &_right {
    text-align: right;
  }

  &_center {
    text-align: center;
  }
}

.italic {
  font-style: italic;
}

.dimmed {
  opacity: 0.75;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.isSpan {
    display: inline-block;
  }
}

.fullWidth {
  display: block;
  width: 100%;
}

.wrapWords {
  word-break: break-word;
}

.lineEllipsis {
  // Browsers only recognize the -webkit- value and property
  /* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  /* stylelint-enable value-no-vendor-prefix, property-no-vendor-prefix */
  overflow: hidden;
}

.isDd {
  margin-left: 0;
}

.visuallyHidden {
  @include mixins.visually-hidden();
}
