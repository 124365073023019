@use '../../../assets/styles/variables/colors/colors';
@use '../../../assets/styles/variables/sizes';
@use '../../../assets/styles/mixins/resets';
@use '../../../assets/styles/variables/border-radius';
@use '../../../assets/styles/variables/spaces';
@use '../../../assets/styles/variables/shadows';

$min-child-panel-width: 300px;

.tagContainer {
  position: relative;
}

.panelContent {
  position: absolute;
  top: calc(100% + #{spaces.$space-size-2});
  border-radius: border-radius.$border-radius-small;
  border: 1px solid colors.$color-border;
  min-width: $min-child-panel-width;
  width: 100%;
  box-shadow: shadows.$box-shadow-default;
  background-color: colors.$color-white;
  z-index: 1;
}

.tagButton,
.removeTagButton {
  @include resets.reset-button();
}

.tagButton {
  border-radius: border-radius.$border-radius-default;
  width: 100%;
  height: sizes.$height-input-small;
  background-color: var(colors.$css-var-name-theme-color-light);
  padding-inline: spaces.$space-size-3 spaces.$space-size-8;
}

.removeTagButton {
  position: absolute;
  right: 0;
}

.removeTagButtonIcon {
  vertical-align: middle;
}
