@use 'src/assets/styles/variables/sizes';
@use './variables/sizes.scss' as app-sizes;
@use './mixins/mixins';
@use './variables/colors/colors';
@use './variables/typography';

/* noto-sans-regular - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Noto Sans'), local('NotoSans'),
    url('../fonts/noto-sans-v7-latin-regular.woff2') format('woff2'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-italic - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Noto Sans Italic'), local('NotoSans-Italic'),
    url('../fonts/noto-sans-v7-latin-italic.woff2') format('woff2'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-700 - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Noto Sans Bold'), local('NotoSans-Bold'),
    url('../fonts/noto-sans-v7-latin-700.woff2') format('woff2'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-700italic - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Noto Sans Bold Italic'), local('NotoSans-BoldItalic'),
    url('../fonts/noto-sans-v7-latin-700italic.woff2') format('woff2'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Noto Sans';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: local('Noto Sans Medium'), local('NotoSans-Medium'),
    url('../fonts/noto-sans-medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Noto Sans';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: local('Noto Sans SemiBold'), local('NotoSans-SemiBold'),
    url('../fonts/noto-sans-semi-bold.woff2') format('woff2');
}

:root {
  #{sizes.$css-var-name-height-header}: app-sizes.$height-header;
  #{sizes.$css-var-name-height-header-with-border}: app-sizes.$height-header-with-border;
}

:root {
  @include mixins.spread-map(colors.$theme-map-pink);
}

.ThemeBlue {
  @include mixins.spread-map(colors.$theme-map-blue);
}

.ThemeGreen {
  @include mixins.spread-map(colors.$theme-map-green);
}

.ThemeOrange {
  @include mixins.spread-map(colors.$theme-map-orange);
}

.ThemePink {
  @include mixins.spread-map(colors.$theme-map-pink);
}

.ThemePurple {
  @include mixins.spread-map(colors.$theme-map-purple);
}

.ThemeRed {
  @include mixins.spread-map(colors.$theme-map-red);
}

.ThemeTurquoise {
  @include mixins.spread-map(colors.$theme-map-turquoise);
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  margin: 0;
  font-family: typography.$font-family;
  font-size: typography.$font-size-body;
  line-height: typography.$line-height-body;
  background-color: colors.$color-white;
  color: colors.$color-text;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
