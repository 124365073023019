@use '../../../assets/styles/variables/colors/colors';
@use '../../../assets/styles/variables/typography';
@use '../../../assets/styles/variables/spaces';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100px;
  min-width: 100px;
  border-radius: 50%;
  border: 1px solid colors.$color-border;
  box-sizing: unset;
}

.noBorder {
  border-width: 0;
}

.small {
  width: 16px;
  min-width: 16px;
  height: 16px;
}

.normal {
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.large {
  width: 24px;
  min-width: 24px;
  height: 24px;
}

.xLarge {
  width: 32px;
  min-width: 32px;
  height: 32px;
}

.xxLarge {
  width: 60px;
  min-width: 60px;
  height: 60px;
}

.image {
  display: block;
  width: 100%;
}
