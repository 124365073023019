@use 'src/assets/styles/variables/colors/colors';

.saved {
  animation-duration: 2s;
  @media (prefers-reduced-motion: no-preference) {
    animation-name: successUpdate;
    animation-iteration-count: 1;
  }

  @media (prefers-reduced-motion: reduce) {
    background-color: colors.$color-success;
  }
}

@keyframes successUpdate {
  0% {
    background-color: inherit;
  }

  40% {
    background-color: colors.$color-success;
  }

  60% {
    background-color: colors.$color-success;
  }

  100% {
    background-color: inherit;
  }
}
