@use '../../../assets/styles/variables/border-radius';
@use '../../../assets/styles/variables/colors/colors';
@use '../../../assets/styles/variables/shadows';
@use '../../../assets/styles/variables/sizes';
@use '../../../assets/styles/variables/typography';
@use '../../../assets/styles/variables/spaces';
@use '../../../assets/styles/mixins/mixins';

.ContextMenu {
  font-family: inherit;

  &__list {
    @include mixins.focus-styles();
    padding: spaces.$space-size-2 0;
    background-color: colors.$color-white;
    box-shadow: shadows.$box-shadow-down;
    border-radius: border-radius.$border-radius-small;
    border: 1px solid colors.$color-gray-20;
    overflow: auto;
    max-width: 274px;
    max-height: calc(100vh - var(#{sizes.$css-var-name-height-header}));
  }

  &__link {
    display: block;
    width: 100%;
    overflow: hidden;
    padding: spaces.$space-size-3;
    border: 0;
    background-color: transparent;
    color: colors.$color-text;
    font-family: typography.$font-family;
    font-size: typography.$font-size-body;
    text-decoration: none;
    white-space: nowrap;

    &[data-selected][data-reach-menu-item] {
      color: colors.$color-text;
      background-color: colors.$color-gray-05;
    }

    &____wrapText {
      white-space: normal;
    }
  }

  &__content {
    padding: spaces.$space-size-3;

    > * + * {
      margin-top: spaces.$space-size-3;
    }
  }

  &__divider {
    height: 1px;
    background-color: colors.$color-border;
    margin-top: spaces.$space-size-2;
    margin-bottom: spaces.$space-size-2;
    border: 0;
  }
}

[data-reach-menu-popover] {
  z-index: 11;
}

[data-reach-menu] {
  z-index: 12;
}
