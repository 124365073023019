@use 'src/assets/styles/variables/colors/colors';
@use 'src/assets/styles/variables/spaces';

.depth0 {
  height: spaces.$space-size-2;
  border-radius: spaces.$space-size-1;
  background-color: colors.$theme-color-turquoise;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: spaces.$space-size-2;
    right: 2px;
    left: 2px;
    height: spaces.$space-size-1;
    background-color: colors.$color-white;
    border-radius: 2px 2px 0 0;
  }
}

.depth1 {
  height: spaces.$space-size-2;
  border-radius: spaces.$space-size-1;
  background-color: colors.$theme-color-turquoise;
}
