@use '../../../assets/styles/mixins/mixins';
@use '../../../assets/styles/mixins/resets';
@use '../../../assets/styles/variables/border-radius';
@use '../../../assets/styles/variables/colors/colors';
@use '../../../assets/styles/variables/sizes';
@use '../../../assets/styles/variables/typography';
@use '../../../assets/styles/variables/spaces';

.wrapper {
  @include resets.reset-button();

  @include mixins.focus-styles();
  display: flex;
  gap: 0.5rem;
  width: fit-content;
  align-items: center;
  padding-right: spaces.$space-size-5;
  padding-left: spaces.$space-size-5;
  height: sizes.$height-button;
  border-radius: border-radius.$border-radius-small;
  background-color: var(colors.$css-var-name-theme-color-dark);
  font-family: typography.$font-family;
  font-size: typography.$font-size-body;
  font-weight: typography.$font-weight-normal;
  color: colors.$color-white;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.2s ease;

  @media screen and (prefers-reduced-motion: reduce) {
    transition: none;
  }

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: var(colors.$css-var-name-theme-color);
  }

  &[disabled] {
    color: colors.$color-gray-50;
    cursor: not-allowed;
  }
}

.primary {
  display: flex;

  &[disabled] {
    background-color: colors.$color-gray-30;
  }
}

.secondary,
.ghost,
.dangerGhost {
  background-color: transparent;
  color: var(colors.$css-var-name-theme-color-dark);

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: transparent;
  }
}

.secondary {
  border: 1px solid var(colors.$css-var-name-theme-color-dark);

  &[disabled] {
    border: 1px solid colors.$color-gray-30;
  }
}

.danger {
  background-color: colors.$color-alert-dark;

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: colors.$color-alert;
  }
}

.ghost {
  height: auto;
  font-weight: typography.$font-weight-normal;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &[aria-expanded] {
    padding-inline: spaces.$space-size-2;
  }

  &:not([aria-expanded]) {
    padding-inline: 0;
  }

  &[aria-expanded='true'] {
    background-color: var(colors.$css-var-name-theme-color-lighter);

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background-color: var(colors.$css-var-name-theme-color-lighter);
    }
  }
}

.tertiary {
  border: 1px solid var(colors.$css-var-name-theme-color-dark);

  &:hover,
  &:focus {
    border-color: var(colors.$css-var-name-theme-color-dark);
  }
}

.dangerSecondary {
  border: 1px solid colors.$color-alert-dark;
  color: colors.$color-alert-dark;
  background-color: colors.$color-white;

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: colors.$color-alert;
  }
}

.dangerGhost {
  height: auto;
  padding-right: 0;
  padding-left: 0;
  font-weight: typography.$font-weight-normal;
  color: colors.$color-alert-dark;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.tiny {
  height: sizes.$height-button-tiny;
  line-height: sizes.$height-button-tiny;
  padding-inline: spaces.$space-size-1;
}

.compact {
  height: sizes.$height-button-small;
  line-height: sizes.$height-button-small;
}

.default {
  height: sizes.$height-button;
  line-height: sizes.$height-button;
}

.hasIconLeft {
  .icon {
    margin-right: spaces.$space-size-1;
  }
}

.hasIconRight {
  .icon {
    margin-left: spaces.$space-size-1;
  }
}
