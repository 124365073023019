@use 'src/assets/styles/variables/colors/colors';

$line-stroke: #000;
$brush-stroke: #1d565a;
$active-cell: #1d565a;
$inactive-cell: #3646b5;

.chartWrapper {
  width: 100%;
  height: 300px;
}

:export {
  lineStroke: $line-stroke;
  brushStroke: $brush-stroke;
  activeCell: $active-cell;
  inactiveCell: $inactive-cell;
}
