@use 'src/assets/styles/variables/spaces';
@use 'src/assets/styles/variables/colors/colors.scss';

.ErrorWapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: colors.$color-gray-10;
  padding: spaces.$space-size-6 spaces.$space-size-4;
  height: 100%;
  color: colors.$color-gray-60;
}
