@use '../../../assets/styles/mixins/mixins';
@use '../../../assets/styles/variables/colors/colors';

.wrapper {
  box-sizing: unset;

  &:focus-within {
    background-color: colors.$color-gray-05;
  }
}

.input {
  @include mixins.visually-hidden();

  &:focus-visible {
    + label {
      border-radius: inherit;
      box-shadow: colors.$color-focus;
    }
  }
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
