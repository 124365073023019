@use 'src/assets/styles/variables/colors/colors';
@use 'src/assets/styles/variables/typography';
@use 'src/assets/styles/variables/spaces';

.RichText {
  * {
    margin: 0;
  }

  * + * {
    margin-top: spaces.$space-size-3;
  }

  h1 {
    font-size: typography.$font-size-display;
  }

  h2 {
    font-size: typography.$font-size-heading;
  }

  h3 {
    font-size: typography.$font-size-sub-heading;
  }

  blockquote {
    padding-block: spaces.$space-size-1;
    padding-left: spaces.$space-size-4;
    border-left: spaces.$space-size-1 solid colors.$color-gray-10;
  }

  p:empty {
    height: typography.$line-height-body * typography.$font-size-body;
    margin-top: 0;
  }

  a {
    color: colors.$theme-color-pink-dark;
  }

  hr {
    height: 1px;
    border: 0;
    margin-block: spaces.$space-size-8;
    background-color: colors.$color-border;
  }

  img {
    display: inline-block;
    max-width: 100%;
  }

  pre {
    color: colors.$color-white;
    background-color: colors.$color-gray-90;
    padding: spaces.$space-size-4;
    overflow: auto;
  }

  strong {
    font-weight: typography.$font-weight-bold;
  }

  li + li {
    margin-top: spaces.$space-size-1;
  }
}
