@use './theme';

$theme-color-red: #da7878;
$theme-color-red-dark: #ba3737;
$theme-color-red-light: #e7c6c6;
$theme-color-red-lighter: #f6e7e7;

// red theme map for generating css custom properties
$theme-map-red: (
  theme.$css-var-name-theme-color: $theme-color-red,
  theme.$css-var-name-theme-color-dark: $theme-color-red-dark,
  theme.$css-var-name-theme-color-light: $theme-color-red-light,
  theme.$css-var-name-theme-color-lighter: $theme-color-red-lighter,
);
