@use '../../../assets/styles/mixins/resets';
@use '../../../assets/styles/variables/colors/colors';
@use '../../../assets/styles/variables/typography';
@use '../../../assets/styles/variables/spaces';

.listNumbered {
  @include resets.reset-list();
  counter-reset: step-counter;
  font-size: typography.$font-size-sub-heading;
}

.listItem {
  position: relative;
  counter-increment: step-counter;
  padding-left: spaces.$space-size-8;

  &::before {
    content: counter(step-counter);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    min-width: spaces.$space-size-5;
    width: spaces.$space-size-5;
    height: spaces.$space-size-5;
    line-height: 17px;
    font-size: typography.$font-size-tips;
    font-weight: 700;
    text-align: center;
    color: var(colors.$css-var-name-theme-color-dark);
    border: 1px solid var(colors.$css-var-name-theme-color-dark);
    border-radius: 50%;
  }

  & + & {
    margin-top: spaces.$space-size-4;
  }
}
