@use '../../../assets/styles/variables/colors/colors';
@use '../../../assets/styles/variables/typography';

.Heading {
  line-height: 1.2;
  font-weight: typography.$font-weight-normal;

  &____1 {
    font-size: typography.$font-size-display;
  }

  &____2 {
    font-size: typography.$font-size-heading;
  }

  &____3 {
    font-size: typography.$font-size-sub-heading;
  }

  &____4 {
    font-size: typography.$font-size-body;
  }

  &____bold {
    font-weight: typography.$font-weight-bold;
  }

  &____semibold {
    font-weight: typography.$font-weight-semibold;
  }

  &____medium {
    font-weight: typography.$font-weight-medium;
  }
}
