@use '../../../../assets/styles/mixins/media-queries';
@use '../../../../assets/styles/mixins/mixins';
@use '../../../../assets/styles/mixins/resets';
@use '../../../../assets/styles/variables/border-radius';
@use '../../../../assets/styles/variables/breakpoints';
@use '../../../../assets/styles/variables/shadows';
@use '../../../../assets/styles/variables/colors/colors';
@use '../../../../assets/styles/variables/sizes';
@use '../../../../assets/styles/variables/spaces';
@use '../../../../assets/styles/variables/typography';

.dateInputs {
  display: inline-flex;
  align-items: center;
  border: 1px solid colors.$color-border;
  border-radius: border-radius.$border-radius-small;

  &.hasError {
    border-color: colors.$color-alert-dark;
  }
}

.form {
  position: relative;
}

.dropdown {
  position: absolute;
  top: calc(100% + #{spaces.$space-size-3});
  left: 0;
  z-index: 10;
  border: 1px solid colors.$color-border;
  border-radius: border-radius.$border-radius-default;
  background-color: colors.$color-white;
  box-shadow: shadows.$box-shadow-down;

  @include media-queries.from(breakpoints.$breakpoint-small) {
    &:not([hidden]) {
      display: flex;
    }
  }
}

.preselects {
  padding-block: spaces.$space-size-2;
  min-width: 120px;

  @include media-queries.until(breakpoints.$breakpoint-small) {
    display: flex;
    gap: spaces.$space-size-3;
    padding-inline: spaces.$space-size-2;
    flex-wrap: wrap;
    border-bottom: 1px solid colors.$color-border;
  }
}

.calendar {
  padding: spaces.$space-size-4 spaces.$space-size-2;

  @include media-queries.from(breakpoints.$breakpoint-small) {
    padding: spaces.$space-size-4;

    &.hasPreselect {
      border-left: 1px solid colors.$color-border;
    }
  }
}

.isFocused {
  border-bottom: 2px solid var(colors.$css-var-name-theme-color);
}
