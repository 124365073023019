@use '../mixins/media-queries';
@use '../variables/breakpoints';
@use '../variables/colors/colors';
@use '../variables/sizes';
@use '../variables/typography';
@use '../variables/spaces';

// hide content in an accessible way
@mixin visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  padding: 0;
}

// Used for theme colors
@mixin spread-map($map: ()) {
  @each $key, $value in $map {
    #{$key}: $value;
  }
}

// We only want focus to happen when
// happens through keyboard
// stylelint-disable a11y/no-outline-none
// Mixin for focus styles
@mixin focus-styles() {
  &:focus {
    outline: 0;
  }

  &:focus-visible {
    box-shadow: colors.$color-focus;
  }
}
// stylelint-enable a11y/no-outline-none
