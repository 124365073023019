@use 'src/assets/styles/variables/colors/colors';
@use 'src/assets/styles/variables/shadows';
@use 'src/assets/styles/variables/sizes';
@use 'src/assets/styles/variables/spaces';

$max-width-side-panel: 300px;

.mainWrapperWithSideBar:not(.mainWrapperWithSideBarLeft) {
  padding-right: $max-width-side-panel;
}

.mainWrapperWithSideBarLeft {
  padding-left: $max-width-side-panel;
}

.sidePanel {
  position: fixed;
  top: var(#{sizes.$css-var-name-height-header-with-border}, 49px);
  bottom: 0;
  z-index: 11;
  width: 100%;
  max-width: $max-width-side-panel;
  padding-bottom: spaces.$space-size-5;
  background-color: colors.$color-white;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.sidePanelLeft {
  border-right: 1px solid colors.$color-border;
  box-shadow: shadows.$box-shadow-right;
  left: 0;
}

.sidePanelRight {
  right: 0;
  border-left: 1px solid colors.$color-border;
  box-shadow: shadows.$box-shadow-left;
}
