@use 'sass:math';
@use '../../../assets/styles/mixins/resets';
@use '../../../assets/styles/variables/colors/colors';
@use '../../../assets/styles/variables/sizes';
@use '../../../assets/styles/variables/typography';
@use '../../../assets/styles/variables/spaces';

@forward './TableColors.module';

.table {
  width: 100%;
  // This is instead of border-collapse: collapse
  // as that will make borders transparent when
  // we use sticky header
  border-spacing: 0;
  vertical-align: middle;
  font-size: typography.$font-size-table;

  & & {
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0;

    .tableRow {
      &:last-child {
        // We have to have all the selectors to target cells
        // in nested tables
        // stylelint-disable-next-line
        > .tableCell {
          border-bottom-width: 0;
        }
      }
    }

    .tableCell {
      &:first-child {
        border-left-width: 0;
      }
    }
  }
}

.tableCell {
  height: sizes.$height-table-cell;
  padding: spaces.$space-size-1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: colors.$color-white;
  font-weight: typography.$font-weight-normal;
}

tbody {
  .tableCell {
    border-top: 1px solid colors.$color-border;
    border-left: 1px solid colors.$color-border;

    &:first-child {
      border-left: 1px solid colors.$color-border;
    }

    &:last-child {
      border-right: 1px solid colors.$color-border;
    }

    &.tableCellTotalColumn {
      border-left: 2px solid colors.$color-border-dark;
      font-weight: typography.$font-weight-semibold;
    }

    &.tableCellDividerBorderLeft {
      background-image: linear-gradient(colors.$color-border-dark 50%, colors.$color-white 0%);
      background-position: left;
      background-size: 1px 5px;
      background-repeat: repeat-y;
      border-left-style: none;
    }

    &.tableCellNoBorderLeft {
      border-left-color: transparent;
    }
  }

  &:not(:first-child) tr:first-child .tableCell {
    border-top-width: 0;
  }
}

// disabled. It make sense to have nested rules, for table hover. since its a table styling rule
/* stylelint-disable selector-max-compound-selectors */
.tableHover {
  // Disable for stylelint focus fule, since tr element can't have focus.
  /* stylelint-disable a11y/selector-pseudo-class-focus */
  tbody tr:hover:not(:has(button:hover)) {
    .tableCell:not([class*='bg']) {
      background-color: colors.$theme-color-pink-lighter;
    }
  }

  .tableCell input:hover:not(:focus) {
    border: 1px solid colors.$theme-color-pink;
  }
  /* stylelint-enable */
}

tfoot {
  tr:first-child .tableCell {
    border-top: 2px solid colors.$color-border-dark;
  }

  .tableCell {
    font-weight: typography.$font-weight-semibold;

    &.tableCellTotalColumn {
      font-weight: typography.$font-weight-bold;
    }
  }
}

thead + tbody,
tbody:only-child {
  tr:last-child {
    // stylelint-disable-next-line selector-max-compound-selectors
    .tableCell {
      border-bottom: 1px solid colors.$color-border;
    }
  }
}

// We only want the border and heavy font weight if it is inside thead
thead .tableCellHeader {
  font-weight: typography.$font-weight-bold;
  border-bottom: 2px solid colors.$color-border-dark;
}

.tableCellHeader {
  text-align: left;
}

.tableCellWrap {
  height: auto;
  white-space: normal;
  padding-top: spaces.$space-size-1;
  padding-bottom: spaces.$space-size-1;
  line-height: 1.4;
}

.tableCellFontColorAlertDark {
  color: colors.$color-alert-dark;
}

.tableCellAlign {
  &_left {
    text-align: left;
  }

  &_center {
    text-align: center;
  }

  &_right {
    text-align: right;
  }
}

.hasOverflow {
  overflow: visible;
}

.tableCellHasIcon {
  position: relative;
  padding-right: 30px;
}

.isSortable {
  position: relative;
  padding-right: sizes.$height-table-cell;

  .compact & {
    padding-right: sizes.$height-table-cell-small;
  }
}

.tableCellIndent {
  &_0 {
    padding-left: inherit;
  }

  &_1 {
    padding-left: spaces.$space-size-1 + sizes.$height-button-small;
  }

  &_2 {
    padding-left: spaces.$space-size-1 + sizes.$height-button-small * 2;
  }

  &_3 {
    padding-left: spaces.$space-size-1 + sizes.$height-button-small * 3;
  }

  &_4 {
    padding-left: spaces.$space-size-1 + sizes.$height-button-small * 4;
  }

  &_5 {
    padding-left: spaces.$space-size-1 + sizes.$height-button-small * 5;
  }

  &_6 {
    padding-left: spaces.$space-size-1 + sizes.$height-button-small * 6;
  }
}

.stickyFirstColumn {
  .tableCell:first-child {
    position: sticky;
    left: 0;
    z-index: 4;
  }

  tbody .tableCell:first-child {
    border-right: 1px solid colors.$color-border;
  }

  tbody .tableCell:nth-child(2) {
    border-left: 0;
  }
}

.stickyLastColumn {
  .tableCell:last-child {
    position: sticky;
    right: 0;
    z-index: 4;
  }
}

.stickyFooter tfoot {
  position: sticky;
  bottom: 0;
  z-index: 6;
  // stylelint-disable selector-max-compound-selectors
  tr:last-child td {
    // this is to make room for the whatfix to make sure whatfix doesn't block the sticky footer
    border-bottom: spaces.$space-size-8 solid colors.$color-white;
  }
}

.tableCellIcon {
  position: absolute;
  top: 50%;
  right: spaces.$space-size-2;
  transform: translateY(-50%);
}

.tableOverflowText {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  vertical-align: middle;
}

// Handle sticky thead
.stickyHeader thead {
  .tableCellHeader {
    position: sticky;
    z-index: 5;
    top: var(sizes.$css-var-name-height-header, 0);

    &:first-child,
    &:last-child {
      z-index: 6; // Position on top of sticky header
    }
  }
}

/**
  *
  *  Global table modifiers
  *
  */
.compact {
  .tableCell {
    height: sizes.$height-table-cell-small;
  }
}

.big {
  .tableCell {
    height: sizes.$height-table-cell-large;
  }
}

// Sort button
.sortButton {
  @include resets.reset-button();
  position: absolute;
  top: 0;
  right: 0;
  width: sizes.$height-table-cell;
  height: sizes.$height-table-cell;
  cursor: pointer;

  .compact & {
    width: sizes.$height-table-cell-small;
    height: sizes.$height-table-cell-small;
  }
}

.sortButtonUp,
.sortButtonDown {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
}

.sortButtonUp {
  transform: translate(-50%, -#{spaces.$space-size-2});
  border-color: transparent transparent colors.$color-gray-50;
  border-width: 0 4px 5px;

  &_active {
    border-bottom-color: colors.$color-gray-70;
  }
}

.sortButtonDown {
  transform: translate(-50%, 0);
  border-color: colors.$color-gray-50 transparent transparent;
  border-width: 5px 4px 0;

  &_active {
    border-top-color: colors.$color-gray-70;
  }
}

// Column widths
// generate .colWidth_xx {width: xx%} in increments of 10
@for $i from 1 through 30 {
  .colWidth_#{$i*10} {
    width: (10px) * $i;
  }
}

.colWidth_auto {
  width: auto;
}

.tableLayout {
  &_fixed {
    table-layout: fixed;
  }

  &_auto {
    table-layout: auto;
  }

  &_initial {
    table-layout: initial;
  }

  &_inherit {
    table-layout: inherit;
  }
}
