@use '../../../assets/styles/variables/spaces';

.flex {
  display: flex;
  align-items: center;
  gap: spaces.$space-size-2;
}

.alignHorizontal {
  &_center {
    justify-content: center;
  }

  &_right {
    justify-content: flex-end;
  }

  &_left {
    justify-content: flex-start;
  }

  &_justify {
    justify-content: space-between;
  }
}

.alignVertical {
  &_top {
    align-items: flex-start;
  }

  &_center {
    align-items: center;
  }

  &_bottom {
    align-items: flex-end;
  }
}

.direction {
  &_rowReverse {
    flex-direction: row-reverse;
  }

  &_columnReverse {
    flex-direction: column-reverse;
  }

  &_row {
    flex-direction: row;
  }

  &_column {
    flex-direction: column;
  }
}

.xxSmall {
  gap: spaces.$space-size-1;
}

.xSmall {
  gap: spaces.$space-size-2;
}

.small {
  gap: spaces.$space-size-3;
}

.medium {
  gap: spaces.$space-size-4;
}

.large {
  gap: spaces.$space-size-5;
}

.xLarge {
  gap: spaces.$space-size-6;
}

.xxLarge {
  gap: spaces.$space-size-8;
}

.noGap {
  gap: 0;
}

.wrap {
  flex-wrap: wrap;
}

.item {
  display: block;
}

.itemFillRest {
  flex: 1;
  // we need to have min-width or else the item will
  // be as wide as the flex container and thereby overflow
  // https://dfmcphee.com/flex-items-and-min-width-0
  min-width: 0;
}
