@use 'src/assets/styles/variables/colors/colors';

.nowButton {
  margin-top: -3px;
  position: absolute;

  &:hover,
  &:focus {
    background-color: transparent;
  }

  > svg {
    fill: colors.$theme-color-pink !important;
  }
}
