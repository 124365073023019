@use '../../../assets/styles/mixins/bool-input';
@use '../../../assets/styles/variables/colors/colors';
@use '../../../assets/styles/variables/spaces';

.wrapper {
  @include bool-input.boolean-input-wrapper();
}

.small {
  @include bool-input.boolean-input-wrapper-small();
}

.normal {
  @include bool-input.boolean-input-wrapper();
}

.icon {
  display: none;
  margin-top: 2px;
  margin-left: 2px;
  width: spaces.$space-size-3;
  height: spaces.$space-size-3;
}

.label {
  @include bool-input.boolean-input-label();
}

.labelText {
  @include bool-input.boolean-input-label-text();
}

.pseudoControl {
  @include bool-input.boolean-input-pseudo-control();
}

.control {
  @include bool-input.boolean-input-control();

  &:checked {
    + .label .icon {
      display: block;
    }
  }

  &:focus {
    + .label .pseudoControl {
      box-shadow: colors.$color-focus;
    }

    &:not(:focus-visible) {
      + .label .pseudoControl {
        outline: 0;
      }
    }
  }

  &[disabled] {
    + .label {
      cursor: not-allowed;
    }

    + .label .pseudoControl {
      background-color: colors.$color-gray-10;
    }

    ~ .label .labelText {
      color: colors.$color-gray-60;
    }
  }
}
