@use '../../../assets/styles/mixins/resets';
@use '../../../assets/styles/variables/colors/colors';
@use '../../../assets/styles/variables/sizes';

.clearInput {
  @include resets.reset-button();
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: sizes.$height-input;
  height: 100%;
  color: colors.$color-gray-60;
}
