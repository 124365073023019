@use 'src/assets/styles/variables/colors/colors';
@use 'src/assets/styles/variables/border-radius';

.iconButtonGroup {
  display: inline-flex;
  align-items: center;
  background-color: colors.$color-white;
  border-radius: border-radius.$border-radius-small;

  > * {
    border-radius: 0;

    &:first-child {
      border-top-left-radius: border-radius.$border-radius-small;
      border-bottom-left-radius: border-radius.$border-radius-small;
    }

    &:last-child {
      border-top-right-radius: border-radius.$border-radius-small;
      border-bottom-right-radius: border-radius.$border-radius-small;
    }
  }
}
