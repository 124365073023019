@use '../global/grays';
@use '../themes/theme';

$color-border: grays.$color-gray-20;
$color-border-dark: grays.$color-gray-80;
$color-input-border: $color-border;

$color-text: grays.$color-gray-80;

$color-focus: 0 0 0 2px grays.$color-white, 0 0 0 4px var(theme.$css-var-name-theme-color-dark);

$color-focus-imitate: rgba(0, 103, 244, 0.247);
