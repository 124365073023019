@use '../../../assets/styles/variables/border-radius';
@use '../../../assets/styles/variables/colors/colors';
@use '../../../assets/styles/variables/spaces';

$width-box-small: 500;
$width-box-medium: 700;
$width-box-large: 900;
$width-box-xlarge: 1100;

.fullWidth {
  width: 100%;
}

.small {
  max-width: $width-box-small * 1px;
}

.medium {
  max-width: $width-box-medium * 1px;
}

.large {
  max-width: $width-box-large * 1px;
}

.xLarge {
  max-width: $width-box-xlarge * 1px;
}

.wrapper {
  border: 1px solid colors.$color-border;
  border-radius: border-radius.$border-radius-small;
}

.item {
  padding: spaces.$space-size-5 spaces.$space-size-6;

  & + & {
    border-top: 1px solid colors.$color-border;
  }
}

.hasActions {
  position: relative;
  padding-right: spaces.$space-size-6;

  &:hover,
  &:focus-within {
    .actions {
      opacity: 1;
      pointer-events: initial;
    }
  }
}

.compact {
  padding: spaces.$space-size-4;
}

.actions {
  opacity: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  padding-right: spaces.$space-size-2;
  padding-left: spaces.$space-size-2;
  position: absolute;
  top: spaces.$space-size-2;
  right: 0;
  height: 100%;
  z-index: 1;

  @media (prefers-reduced-motion: no-preference) {
    transition: opacity 0.2s ease;
  }

  > * {
    margin-top: spaces.$space-size-1;
    margin-bottom: spaces.$space-size-1;
  }
}
