@use 'src/assets/styles/variables/colors/colors';
@use 'src/assets/styles/variables/spaces';
@use 'src/assets/styles/variables/sizes.scss' as app-sizes;

.Wrapper {
  border: 1px solid colors.$theme-color-pink-dark;
  background-color: colors.$theme-color-pink;
  padding: 0 spaces.$space-size-1 spaces.$space-size-1;

  :global {
    // rich text editor menu must be placed over image resize handlers
    // but the internal remirror className breaks our style linting rules
    // stylelint-disable selector-class-pattern, selector-max-compound-selectors
    .remirror-resizable-view > div {
      z-index: 0 !important;
    }
  }

  :not(img)[contenteditable='true'] {
    background-color: colors.$color-white;
    padding: spaces.$space-size-4;
    min-height: 300px;

    &:focus-visible {
      outline: 2px solid colors.$theme-color-pink-dark;
    }
  }
}

.Menu {
  position: sticky;
  top: app-sizes.$height-header-with-border;
  z-index: 1;
  padding-block: spaces.$space-size-1 spaces.$space-size-2;
  background-color: inherit;
}
