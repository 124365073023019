@use './theme';

$theme-color-blue: #8aa9e3;
$theme-color-blue-dark: #5975b5;
$theme-color-blue-light: #dbe6fb;
$theme-color-blue-lighter: #e9f1ff;

// Blue theme map for generating css custom properties
$theme-map-blue: (
  theme.$css-var-name-theme-color: $theme-color-blue,
  theme.$css-var-name-theme-color-dark: $theme-color-blue-dark,
  theme.$css-var-name-theme-color-light: $theme-color-blue-light,
  theme.$css-var-name-theme-color-lighter: $theme-color-blue-lighter,
);
