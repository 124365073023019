@use '../../../assets/styles/mixins/bool-input';
@use '../../../assets/styles/variables/colors/colors';
@use '../../../assets/styles/variables/sizes';
@use '../../../assets/styles/variables/spaces';

.wrapper {
  @include bool-input.boolean-input-wrapper();
}

.label {
  @include bool-input.boolean-input-label();
}

.labelText {
  @include bool-input.boolean-input-label-text();
}

.pseudoControl {
  @include bool-input.boolean-input-pseudo-control(true);
}

.control {
  @include bool-input.boolean-input-control();

  &:checked {
    + .label .pseudoControl {
      box-shadow: inset 0 0 0 1px colors.$color-gray-55, inset 0 0 0 4px colors.$color-white,
        inset 0 0 0 8px var(colors.$css-var-name-theme-color-dark);
    }
  }

  &:focus {
    + .label .pseudoControl {
      border-color: var(colors.$css-var-name-theme-color-dark);
      outline: 5px solid colors.$color-focus-imitate; // double value — IE/EDGE does not support auto
      outline: 5px auto colors.$color-focus-imitate;
    }

    &:not(:focus-visible) {
      + .label .pseudoControl {
        outline: 0;
      }
    }
  }

  &[disabled] {
    + .label .pseudoControl {
      background-color: colors.$color-gray-90;
      border-color: colors.$color-gray-80;
    }

    ~ .label .labelText {
      color: colors.$color-gray-60;
    }
  }
}
