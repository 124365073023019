@use 'src/assets/styles/mixins/media-queries.scss';
@use 'src/assets/styles/variables/border-radius.scss';
@use 'src/assets/styles/variables/colors/colors.scss';
@use 'src/assets/styles/variables/spaces.scss';

.Toast {
  position: relative;
  min-height: 56px;
  margin-bottom: spaces.$space-size-2;
  padding: spaces.$space-size-2 spaces.$space-size-4;
  border-radius: border-radius.$border-radius-default;
  overflow: hidden;
  font-family: inherit;
  cursor: default;
  background: colors.$color-gray-02;
  padding-right: spaces.$space-size-12 + spaces.$space-size-4;
  border: 1px solid colors.$color-gray-20;
}

.Error {
  background-color: colors.$color-alert-light;
  color: colors.$color-alert-dark;
  border-color: colors.$color-alert-dark;
}

.Success {
  background-color: colors.$color-success-light;
  color: colors.$color-success-dark;
  border-color: colors.$color-success-dark;
}

.Warning {
  background-color: colors.$color-warning-light;
  color: colors.$color-text;
  border-color: colors.$color-warning;
}
