@use 'sass:math';

@use 'src/assets/styles/mixins/media-queries';
@use 'src/assets/styles/mixins/resets';
@use 'src/assets/styles/variables/breakpoints';
@use 'src/assets/styles/variables/colors/colors';
@use 'src/assets/styles/variables/spaces';
@use 'src/assets/styles/variables/typography';

.wrapper {
  padding: spaces.$space-size-12 0 spaces.$space-size-6 spaces.$space-size-4;

  @include media-queries.from(breakpoints.$breakpoint-small) {
    padding-block: 7vw;
  }

  @include media-queries.from(breakpoints.$breakpoint-medium) {
    padding-inline: 10vw 0;
  }
}

.intro {
  position: relative;
  padding-block: spaces.$space-size-12;

  @include media-queries.from(breakpoints.$breakpoint-small) {
    padding-block: 4vw;
  }
}

.introContent {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media-queries.from(breakpoints.$breakpoint-xsmall) {
    width: 66%;
    // We have to hardcode values based on image size,
    // as it would be impossible to align with that added
    // shadow in the image (not possible with css)
    min-height: 395px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: spaces.$space-size-12;
      left: 66%;
      width: 34%;
      // We have to hardcode values based on image size,
      // as it would be impossible to align with that added
      // shadow in the image (not possible with css)
      min-height: 578px;
      background-image: url('../../../../assets/images/rp-import-wait.webp');
      background-repeat: no-repeat;
      background-position: left top;
    }
  }

  @include media-queries.from(breakpoints.$breakpoint-small) {
    width: 50%;

    &::after {
      top: 4vw;
      left: 50%;
      width: 50%;
    }
  }

  @include media-queries.from(breakpoints.$breakpoint-medium) {
    width: 33%;
  }
}

.progressSteps {
  @include resets.reset-list();
  position: relative;
  display: table;
  table-layout: fixed;
  width: 100%;
  counter-reset: step-counter;
  padding-right: spaces.$space-size-4;

  @include media-queries.from(breakpoints.$breakpoint-medium) {
    padding-right: 10vw;
  }

  > .progressStepsItem {
    position: relative;
    display: table-cell;
    text-align: center;
    padding-inline: spaces.$space-size-2;
    background-color: colors.$color-white;
    counter-increment: step-counter;

    &::before {
      content: counter(step-counter);
      display: block;
      margin: 0 auto spaces.$space-size-2;
      min-width: spaces.$space-size-5;
      width: spaces.$space-size-5;
      height: spaces.$space-size-5;
      line-height: 17px;
      font-size: typography.$font-size-tips;
      font-weight: 700;
      text-align: center;
      color: var(colors.$css-var-name-theme-color-dark);
      border: 1px solid var(colors.$css-var-name-theme-color-dark);
      border-radius: 50%;
    }

    &:not(:last-child)::after {
      content: '';
      display: block;
      position: absolute;
      top: math.div(spaces.$space-size-5, 2);
      left: 50%;
      left: calc(50% + spaces.$space-size-6);
      z-index: 1;
      width: 100%;
      width: calc(100% - spaces.$space-size-12);
      height: 1px;
      background-color: var(colors.$css-var-name-theme-color-dark);
    }

    &.isCurrent::before {
      background-color: var(colors.$css-var-name-theme-color-dark);
      color: colors.$color-white;
    }
  }
}
