@use './theme';

$theme-color-orange: #f5a856;
$theme-color-orange-dark: #d76f00;
$theme-color-orange-light: #f2eecd;
$theme-color-orange-lighter: #fbf9e6;

// orange theme map for generating css custom properties
$theme-map-orange: (
  theme.$css-var-name-theme-color: $theme-color-orange,
  theme.$css-var-name-theme-color-dark: $theme-color-orange-dark,
  theme.$css-var-name-theme-color-light: $theme-color-orange-light,
  theme.$css-var-name-theme-color-lighter: $theme-color-orange-lighter,
);
