@use '../../../assets/styles/variables/border-radius';
@use '../../../assets/styles/variables/colors/colors';
@use '../../../assets/styles/variables/spaces';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100px;
  min-width: 100px;
}

.image {
  display: block;
  width: 100%;
}

.noImage {
  text-align: center;
}

.border {
  border: 1px solid colors.$color-border;
  border-radius: border-radius.$border-radius-small;

  &:not(.round) {
    padding: spaces.$space-size-2;
  }
}

.round {
  border-radius: 50%;
}

.small {
  width: 24px;
  min-width: 24px;

  &.round {
    height: 24px;
  }
}

.large {
  width: 140px;
  min-width: 140px;

  &.round {
    height: 140px;
  }
}
