@use '../../../assets/styles/mixins/resets';
@use '../../../assets/styles/variables/colors/colors';
@use '../../../assets/styles/variables/sizes';
@use '../../../assets/styles/variables/spaces';

.wrapper {
  > * + * {
    margin-top: spaces.$space-size-4;
  }
}

.button {
  @include resets.reset-button();
  text-align: left;
  width: 100%;
  font-weight: inherit;
  font-size: inherit;
  border-bottom: 1px solid colors.$color-border;
  padding-block: spaces.$space-size-2;
  cursor: pointer;
}

.icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: sizes.$height-button-small;
  height: sizes.$height-button-small;
}

.panel {
  padding-block: spaces.$space-size-1;
}
