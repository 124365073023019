@use '../../../assets/styles/mixins/mixins';
@use '../../../assets/styles/variables/colors/colors';
@use '../../../assets/styles/variables/spaces';

@media screen and (prefers-reduced-motion: reduce) {
  .item {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(colors.$css-var-name-theme-color-dark);
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: none;

    @media (prefers-reduced-motion: reduce) {
      display: none;
    }

    &.second {
      animation-delay: -1s;
    }
  }
}

.wrapper {
  position: relative;
  width: spaces.$space-size-12;
  height: spaces.$space-size-12;
  margin: auto;

  @media (prefers-reduced-motion: reduce) {
    width: 100%;
    height: auto;
    text-align: center;
  }

  &.small {
    width: spaces.$space-size-5;
    height: spaces.$space-size-5;
  }
}

.item {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(colors.$css-var-name-theme-color-dark);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: spinner-bounce 2s infinite ease-in-out;

  @media (prefers-reduced-motion: reduce) {
    display: none;
  }

  &.second {
    animation-delay: -1s;
  }
}

.message {
  font-size: 2rem;

  @media (prefers-reduced-motion: no-preference) {
    @include mixins.visually-hidden();
  }
}

@keyframes spinner-bounce {
  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}
