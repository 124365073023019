@use '../../../assets/styles/variables/colors/colors';
@use '../../../assets/styles/variables/spaces';
@use '../../../assets/styles/variables/typography';

.link {
  display: inline-flex;
  color: var(colors.$css-var-name-theme-color-dark);
  text-decoration: none;

  &:hover {
    /* stylelint-disable-line */
    text-decoration: underline; // We don't want this rule to hit foucs, since it creates a wired behavior when ctrl clicking links. They will stay underlined
  }
}

.icon {
  margin-right: spaces.$space-size-2;
  width: spaces.$space-size-4;
  height: spaces.$space-size-4;
}

.hasIcon {
  align-items: center;
}

.block {
  width: 100%;
}

.bold {
  font-weight: typography.$font-weight-bold;
}

.gray {
  color: colors.$color-text;

  .icon {
    color: colors.$color-gray-70;
  }
}

.underline {
  text-decoration: underline;
}
