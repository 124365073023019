@use '../mixins/media-queries';
@use '../variables/border-radius';
@use '../variables/breakpoints';
@use '../variables/colors/colors';
@use '../variables/sizes';
@use '../variables/typography';
@use '../variables/spaces';

@mixin input-style-wrapper() {
  // We need the wendor prefix for iOS to remove the box-
  // shadow inside inputs.
  // https://www.abeautifulsite.net/posts/how-to-remove-box-shadows-from-input-controls-on-ios/
  // stylelint-disable-next-line property-no-vendor-prefix
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: sizes.$height-input;
  padding: 0 spaces.$space-size-2;
  background-color: colors.$color-white;
  color: inherit;
  border: 1px solid colors.$color-input-border;
  border-radius: border-radius.$border-radius-default;
  font-family: inherit;
  font-size: inherit;
  line-height: 1.8rem; //18px

  // Prevent zooming on iOS mobile Safari
  // https://css-tricks.com/16px-or-larger-text-prevents-ios-form-zoom/
  @include media-queries.until(breakpoints.$breakpoint-small) {
    font-size: typography.$font-size-sub-heading;
  }

  &[disabled] {
    background-color: colors.$color-gray-10;
    color: colors.$color-gray-60;
  }
}

@mixin input-style-normal() {
  height: sizes.$height-input;
}

@mixin input-style-small() {
  height: sizes.$height-input-small;
}

@mixin input-style-align-right() {
  text-align: right;
}

@mixin input-style-status-default() {
  border-color: colors.$color-input-border;
}

@mixin input-style-status-error() {
  border-color: colors.$color-alert;
}

@mixin input-style-status-success() {
  border-color: colors.$color-success;
}

@mixin input-style-status-warning() {
  border-color: colors.$color-warning;
}
