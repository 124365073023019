@use '../../../assets/styles/variables/border-radius';
@use '../../../assets/styles/variables/colors/colors';
@use '../../../assets/styles/variables/spaces';
@use '../../../assets/styles/variables/sizes';
@use '../../../assets/styles/mixins/mixins';

.wrapper {
  width: 100%;

  [class*='-control'] {
    min-height: sizes.$height-input;
    border: 1px solid colors.$color-input-border;
    border-radius: border-radius.$border-radius-small;
    border-width: 1px;
    box-shadow: none;
  }

  [class*='-ValueContainer'] {
    padding-top: 0;
    padding-bottom: 0;
  }

  [class*='-indicatorContainer'] {
    padding-top: spaces.$space-size-1;
    padding-bottom: spaces.$space-size-1;
  }

  [class*='-indicatorSeparator'] {
    display: none;
  }
}

.focusWrapper {
  box-shadow: colors.$color-focus;
}

.iconWrapper {
  padding-inline: spaces.$space-size-2;
}

.icon {
  display: flex;
  align-items: center;
}
