@use 'src/assets/styles/mixins/mixins';
@use 'src/assets/styles/mixins/resets';
@use 'src/assets/styles/variables/border-radius';
@use 'src/assets/styles/variables/colors/colors';
@use 'src/assets/styles/variables/sizes';
@use 'src/assets/styles/variables/spaces';

%hover {
  background-color: colors.$color-gray-05;
}

.iconButton {
  @include resets.reset-button();

  @include mixins.focus-styles();
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: sizes.$finger-tap-size;
  height: sizes.$finger-tap-size;
  color: inherit;
  border-radius: border-radius.$border-radius-small;
  cursor: pointer;
  transition: all 0.2s ease;

  @media screen and (prefers-reduced-motion: reduce) {
    transition: none;
  }

  &:hover,
  &:focus-visible {
    @extend %hover;
  }

  &[data-button-hover] {
    @extend %hover;
  }

  // if we explicitly set the icon color to a tone, then we should leave it with that
  > svg:not([fill^='#']) {
    color: colors.$color-gray-80;
    fill: colors.$color-gray-80;
  }

  &[aria-expanded='true'],
  &[aria-pressed='true'] {
    background-color: colors.$color-gray-05;

    > * {
      color: colors.$color-gray-90;
      fill: colors.$color-gray-90;
    }
  }

  &:disabled {
    cursor: not-allowed;

    > * {
      fill: colors.$color-gray-40;
    }

    &:hover,
    &:focus-visible {
      background-color: transparent;
    }
  }

  &:has(.count) {
    position: relative;
  }
}

.tiny {
  width: sizes.$height-button-tiny;
  height: sizes.$height-button-tiny;
}

.compact {
  width: sizes.$height-button-small;
  height: sizes.$height-button-small;
}

.default {
  width: sizes.$height-button;
  height: sizes.$height-button;
}

.large {
  width: sizes.$height-button-large;
  height: sizes.$height-button-large;
}

.border {
  border: 1px solid colors.$color-border;
}

.round {
  border-radius: border-radius.$border-radius-round;
}

.count {
  position: absolute;
  top: spaces.$space-size-1;
  right: spaces.$space-size-1;
  z-index: 1;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
}

[data-reach-tooltip] {
  z-index: 20;
}
