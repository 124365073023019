@use 'src/assets/styles/variables/spaces';

%common-icon-properties {
  display: inline-flex;
  position: absolute;
}

.IconId {
  @extend %common-icon-properties;
  top: 12px;
  left: spaces.$space-size-1;
}

.IconArrow {
  @extend %common-icon-properties;
  top: 14px;
  right: spaces.$space-size-1;
}
