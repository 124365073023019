// #20230906 DRO: The color codes are hardcoded in this file at the moment since we do not have colors variables for these MUI color codes
// which the team is still WIP. We should update these to variables in the future.
// Example future implementation (https://mui.com/material-ui/experimental-api/css-theme-variables/overview/)

.wrapper {
  $height: 24px;

  display: inline-block;
  position: relative;
  width: 100%;

  &::after {
    content: attr(data-value) '%';
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1;
    color: #fff;
  }

  progress {
    appearance: none;
    width: 100%;
    height: $height;
    background-color: #bdbdbd;
    border-radius: calc($height / 2);
    overflow: hidden;
    border: 0;

    &::-webkit-progress-bar {
      background-color: #bdbdbd;
      border-radius: calc($height / 2);
    }

    &::-webkit-progress-value {
      border-top-right-radius: calc($height / 2);
      border-bottom-right-radius: calc($height / 2);
      @media (prefers-reduced-motion: no-preference) {
        transition: width ease 0.3s;
      }
    }

    &::-moz-progress-bar {
      border-top-right-radius: calc($height / 2);
      border-bottom-right-radius: calc($height / 2);
      @media (prefers-reduced-motion: no-preference) {
        transition: width ease 0.3s;
      }
    }
  }
}

.default {
  &::-webkit-progress-value {
    background-color: #616161;
  }

  &::-moz-progress-bar {
    background-color: #616161;
  }
}

.success {
  &::-webkit-progress-value {
    background-color: #007a00;
  }

  &::-moz-progress-bar {
    background-color: #007a00;
  }
}

.error {
  &::-webkit-progress-value {
    background-color: #c92726;
  }

  &::-moz-progress-bar {
    background-color: #c92726;
  }
}

.warning {
  &::-webkit-progress-value {
    background-color: #ffa726;
  }

  &::-moz-progress-bar {
    background-color: #ffa726;
  }
}
