@use 'sass:math';
@use '../global/grays';

// Gradient for heat map
$color-heatmap-alert: #e75757;
$color-heatmap-success: #627ebe;

$share-positive-heatmap: math.div(100, 21);
$share-negative-heatmap: math.div(100, 4);

// stylelint-disable function-parentheses-space-inside
$color-heatmap-00: $color-heatmap-success;
$color-heatmap-05: mix(grays.$color-white, $color-heatmap-success, ($share-positive-heatmap * 1%));
$color-heatmap-10: mix(grays.$color-white, $color-heatmap-success, ($share-positive-heatmap * 2%));
$color-heatmap-15: mix(grays.$color-white, $color-heatmap-success, ($share-positive-heatmap * 3%));
$color-heatmap-20: mix(grays.$color-white, $color-heatmap-success, ($share-positive-heatmap * 4%));
$color-heatmap-25: mix(grays.$color-white, $color-heatmap-success, ($share-positive-heatmap * 5%));
$color-heatmap-30: mix(grays.$color-white, $color-heatmap-success, ($share-positive-heatmap * 6%));
$color-heatmap-35: mix(grays.$color-white, $color-heatmap-success, ($share-positive-heatmap * 7%));
$color-heatmap-40: mix(grays.$color-white, $color-heatmap-success, ($share-positive-heatmap * 8%));
$color-heatmap-45: mix(grays.$color-white, $color-heatmap-success, ($share-positive-heatmap * 9%));
$color-heatmap-50: mix(grays.$color-white, $color-heatmap-success, ($share-positive-heatmap * 10%));
$color-heatmap-55: mix(grays.$color-white, $color-heatmap-success, ($share-positive-heatmap * 11%));
$color-heatmap-60: mix(grays.$color-white, $color-heatmap-success, ($share-positive-heatmap * 12%));
$color-heatmap-65: mix(grays.$color-white, $color-heatmap-success, ($share-positive-heatmap * 13%));
$color-heatmap-70: mix(grays.$color-white, $color-heatmap-success, ($share-positive-heatmap * 14%));
$color-heatmap-75: mix(grays.$color-white, $color-heatmap-success, ($share-positive-heatmap * 15%));
$color-heatmap-80: mix(grays.$color-white, $color-heatmap-success, ($share-positive-heatmap * 16%));
$color-heatmap-85: mix(grays.$color-white, $color-heatmap-success, ($share-positive-heatmap * 17%));
$color-heatmap-90: mix(grays.$color-white, $color-heatmap-success, ($share-positive-heatmap * 18%));
$color-heatmap-95: mix(grays.$color-white, $color-heatmap-success, ($share-positive-heatmap * 19%));
$color-heatmap-100: mix(
  grays.$color-white,
  $color-heatmap-success,
  ($share-positive-heatmap * 20%)
);

$color-heatmap-105: mix($color-heatmap-alert, grays.$color-white, ($share-negative-heatmap * 1%));
$color-heatmap-110: mix($color-heatmap-alert, grays.$color-white, ($share-negative-heatmap * 2%));
$color-heatmap-115: mix($color-heatmap-alert, grays.$color-white, ($share-negative-heatmap * 3%));
$color-heatmap-120: $color-heatmap-alert;
// stylelint-enable function-parentheses-space-inside
