@use 'src/assets/styles/variables/sizes';
@use 'src/assets/styles/variables/spaces';

.avatar {
  // This is to make sure it does not take more space in height than it needs to
  display: inline-flex;
}

.name {
  padding-left: spaces.$space-size-1;
}

.spacer {
  width: sizes.$height-button-small;
}
