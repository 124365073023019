@use 'src/assets/styles/mixins/resets.scss';
@use 'src/assets/styles/variables/colors/colors.scss';
@use 'src/assets/styles/variables/spaces.scss';
@use 'src/assets/styles/variables/sizes.scss' as app-sizes;

.SearchResults {
  @include resets.reset-list();
  padding: spaces.$space-size-4;
  background-color: colors.$color-white;
  border: 1px solid colors.$color-border;
  border-top-width: 0;
  max-height: calc(100vh - #{app-sizes.$height-header} - #{spaces.$space-size-5} - 65px);
  overflow: auto;

  > li:not(:first-child) {
    margin-top: spaces.$space-size-4;
  }

  [aria-selected='true'] {
    background-color: colors.$color-gray-05;
    box-shadow: 0 0 0 1px colors.$color-gray-40;
  }
}
