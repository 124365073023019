@use 'src/assets/styles/mixins/media-queries';
@use 'src/assets/styles/mixins/resets';
@use 'src/assets/styles/variables/breakpoints';
@use 'src/assets/styles/variables/colors/colors';
@use 'src/assets/styles/variables/spaces';

.preselectButton {
  @include resets.reset-button();
  display: block;
  text-align: left;
  padding: spaces.$space-size-1 spaces.$space-size-2;
  cursor: pointer;

  @include media-queries.from(breakpoints.$breakpoint-small) {
    width: 100%;
    padding: spaces.$space-size-2 spaces.$space-size-4;
  }
}
